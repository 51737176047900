<template>
  <div>
    <!-- end modal section -->

    <b-row class="mb-5">
      <b-col>
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          <b>Permohonan Perubahan </b>
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          size="sm"
          variant="primary"
          @click="addSubmission()"
        >
          <v-icon
            style="color:white;"
            small
          >mdi-plus-box</v-icon>
          Tambah Permohonan
        </b-button>
      </b-col>
    </b-row>
    <div>
      <div
        class="shadow"
        style="border-radius:15px;"
      >
        <div
          class="table-responsive"
          style="border-radius:15px;"
        >
          <table
            class="
          table
          table-head-custom
          table-vertical-center
          table-head-bg
          table-bordered
        "
            style="font-size:14px !important;"
          >
            <thead>
              <tr class="text-left">
                <th style="width: 20px" class="text-capitalize">No.</th>
                <th style="min-width: 50px" class="text-capitalize">Jenis Perubahan Test</th>
                <th style="min-width: 50px" class="text-capitalize">Tanggal Pengajuan</th>
                <th style="min-width: 50px" class="text-capitalize">Tanggal Persetujuan</th>
                <th style="min-width: 50px" class="text-capitalize">Status Penambahan</th>
                <th style="min-width: 50px" class="text-capitalize">Aksi</th>
              </tr>
            </thead>
            <tbody v-if="list.length == 0">
                <template>
                    <tr>
                        <td colspan="6"> -- Tidak ada permohonan -- </td>
                    </tr>
                </template>
            </tbody>
            <tbody v-else style="background:white;">
              <template v-for="(item, i) in list">
                <tr v-bind:key="i">
                  <td >
                    <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    font-size-lg
                  ">{{ i + 1}}</span>
                  </td>
                  <td class="text-left">
                    <span v-for="val in item.mJenisPerubahanValues" :key="val" class="
                    text-dark-75
                    font-weight-bolder
                    text-left
                    font-size-lg
                  "> {{val}},
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{ item.tanggalPerubahan | moment('DD MMMM YYYY') }}</span>
                  </td>
                  <td class="text-left">
                    <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{ item.tanggalPersetujuan | moment('DD MMMM YYYY')}}</span>
                  </td>
                  <td class="text-left">
                    <span class="
                    text-dark-75
                    text-left
                    font-weight-bolder
                    d-block
                    font-size-lg
                  ">{{ item.statusValues }}</span>
                  </td>
                  <td>
                     <b-button variant="success" size="sm" style="width:80px;" @click="detailPerubahan(item)">
                      {{ loading ? '' : '>> Detail'}}
                      <b-spinner small v-if="loading" variant="light"></b-spinner>
                    </b-button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-detail-permohonan"
      ok-only
      centered
      size="xl"
      content-class="shadow"
      hide-header
      hide-footer
      style="background-color:#FAFAFA;"
    >
      <div style="background-color:#FAFAFA;" v-if="det && ormasIdentify">
        <b-container>
          <b-row>
            <b-col cols="12">
              <b-row class="pt-5 mt-2">
                <b-col class="d-flex justify-content-between">
                  <b style="font-size:20px;">
                    Detail Perubahan Ormas
                  </b>
                  <div>
                    <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" @click="$bvModal.hide('modal-detail-permohonan')">
                      <v-icon class="pr-3">mdi-close</v-icon>
                      Tutup
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-2 mt-7">
            <b-col cols="2" style="font-weight:600;">
              Nama Ormas
            </b-col>
            <b-col >
              : {{ ormasIdentify.nama_ormas }}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="2" style="font-weight:600;">
              Ciri Khusus
            </b-col>
            <b-col >
              : {{ ormasIdentify.masterCiriKhusus.nama_ciri_khusus}}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="2" style="font-weight:600;">
              Jenis Perubahan
            </b-col>
            <b-col class="d-flex">
              : &nbsp;<span v-for="(item,index) in det.jenisPerubahan.mJenisPerubahanIds" :key="index">
                    {{ item == 1 ? 'Profil, ' : '' }}
                    {{ item == 2 ? 'Struktur, ' : '' }}
                    {{ item == 3 ? 'Rekening, ' : '' }}
                    {{ item == 4 ? 'Cabang' : '' }}
                    </span>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="2" style="font-weight:600;">
              Tanggal Permohonan
            </b-col>
            <b-col >
              : {{ det.jenisPerubahan.tanggalPerubahan | moment('DD MMMM YYYY')}}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="2" style="font-weight:600;">
              Status Permohonan
            </b-col>
            <b-col >
              : {{ det.jenisPerubahan.status == 0 || det.jenisPerubahan.status == 4 ? 'Sedang Ditinjau' : '' }}
              {{ det.jenisPerubahan.status == 1 ? 'Diterima' : '' }}
              {{ det.jenisPerubahan.status == 2 ? 'Ditolak' : '' }}
            </b-col>
          </b-row>

          <b-card class="mt-5">
            <b-row class="mb-3">
              <b-col>
                <b style="font-size:16px;">
                  Profil
                </b>
              </b-col>
            </b-row>
            <b-row v-if="det.profil">
              <b-col cols="7">
                <b-row class="mb-2">
                  <b-col cols="4" style="font-weight:600;">
                    Nomor Telepon
                  </b-col>
                  <b-col v-if="det.profil">
                    : {{ det.profil.noTelp}}
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col cols="4" style="font-weight:600;">
                    Nomor Fax
                  </b-col>
                  <b-col v-if="det.profil">
                    : {{ det.profil.noFax}}
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col cols="4" style="font-weight:600;">
                    Ciri Khusus
                  </b-col>
                  <b-col v-if="det.profil">
                    : {{ det.profil.masterCiriKhusus.nama_ciri_khusus}}
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col cols="4" style="font-weight:600;">
                    Bidang Kegiatan
                  </b-col>
                  <b-col class="d-flex">
                    : &nbsp;<ul class="ml-5">
                        <li v-for="(item,index) in ormasIdentify.bidangKegiatans" :key="index">{{ item.masterJenisOrmas.nama_jenis_ormas }}</li>
                      </ul>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col cols="4" style="font-weight:600;">
                    Wadah Berhimpun
                  </b-col>
                  <b-col v-if="det.berhimpunOtonom.wadahBerhimpun">
                    : {{ det.berhimpunOtonom.wadahBerhimpun.dataOrmas.nama_ormas}}
                  </b-col>
                </b-row>

                <b-row class="mb-3 mt-5">
                  <b-col style="font-weight:600;">
                    Badan Otonom / Sayap Organisasi
                  </b-col>
                </b-row>

                <b-row class="mb-2" v-if="det.berhimpunOtonom.badanOtonom">
                  <b-col>
                      <div class="table-responsive">
                            <table
                              class="
                                table
                                table-head-custom
                                table-vertical-center
                                table-head-bg
                                table-bordered
                              "
                            >
                              <thead>
                                <tr class="text-left">
                                  <th style="width: 20px"  class="text-capitalize">No.</th>
                                  <th style="min-width: 190px"  class="text-capitalize">Nama Organisasi</th>
                                  <th style="min-width: 100px"  class="text-capitalize">Nomor Registrasi</th>
                                </tr>
                              </thead>
                              <tbody style="background:#FAFAFA;" v-if="det.berhimpunOtonom.badanOtonom.length > 0">
                                <template v-for="(item, i) in det.berhimpunOtonom.badanOtonom">
                                  <tr v-bind:key="i" >
                                    <td>
                                      <span
                                        style="width: 20px"
                                        class="
                                  text-dark-75
                                  font-weight-bolder
                                  d-block
                                  font-size-lg
                                "
                                        >{{ i + 1 }}</span
                                      >
                                    </td>
                                    <td>
                                      <span
                                        class="
                                  text-dark-75
                                  font-weight-bolder
                                  d-block
                                  text-left
                                  font-size-lg
                                "
                                        >{{ item.dataOrmas.nama_ormas }}</span
                                      >
                                    </td>
                                    <td>
                                      <span
                                        class="
                                  text-dark-75
                                  font-weight-bolder
                                  d-block
                                  text-left
                                  font-size-lg
                                "
                                        >{{ item.dataOrmas.kode_transaksi }}</span
                                      >
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <!-- <b-row class="d-flex justify-content-center">
                  <img
                    src="../../../assets/image/images/ormas_picture.png"
                    style="width: 50%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                    alt="abstract"
                  />
                  <img
                    src="../../../assets/image/images/ormas_flag.png"
                    style="width: 50%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                    alt="abstract"
                  />
                </b-row> -->
              </b-col>
            </b-row>

            <b-row class="mb-3" v-if="det.strukturOrganisasi && det.strukturOrganisasi.length > 0">
              <b-col>
                <b style="font-size:16px;">
                  Struktur Organisasi
                </b>
              </b-col>
            </b-row>
            <div class="card-body pt-0 pb-3 pl-0 pr-0">
                <div class="tab-content">
                  <!--begin::Table-->
                  <div class="table-responsive">
                    <table
                      class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              "
                    >
                      <thead>
                        <tr class="text-left">
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto</th>
                          <th style="width: 170px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama</th>
                          <th style="width: 200px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa Bakti</th>
                          <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. Identitas</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Tanggal Lahir</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in pengurus">
                          <tr v-bind:key="i">
                            <td>
                              <img v-if="item.foto !== null" :src="fileURL + '/' + item.foto"
                                style="width:64px;height:64px;"
                                alt="abstract"
                                @error="replaceByDefault"
                                />
                              <img
                                v-if="item.foto == null"
                                src="https://alppetro.co.id/dist/assets/images/default.jpg"
                                style="width:64px;height:64px;"
                                @error="replaceByDefault"
                                alt="abstract"
                              />
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        "
                                >{{ item.nama_pengurus }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        "
                                >{{ item.periode_mulai | moment("DD/MM/YYYY") }} - {{  item.periode_selesai | moment("DD/MM/YYYY") }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                                >{{ item.masterJabatan.nama_jabatan }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-left
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                                >{{ item.identitas }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                                >{{item.tanggal_lahir | moment("DD-MM-YYYY")}}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        "
                                >{{ item.no_hp }}</span
                              >
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                    
                  <!--end::Table-->
                  <div class="mt-5" v-if="det.strukturOrganisasi.length > 0">
                    <b-row class="d-flex align-items-center mb-5">
                  <b-col sm="4"
                    ><p style="font-weight: 600; margin:0px;">SK Susunan Pengurus</p></b-col
                      >:<b-col sm="6">
                        <b-button v-if="ormasIdentify.file_sk_pengurus !== null && ormasIdentify.file_sk_pengurus != ''" size="sm" 
                        @click="newTab(ormasIdentify.file_sk_pengurus)" variant="success">
                        <v-icon style="color:white;" small>mdi-clipboard</v-icon>Lihat File Unggahan</b-button></b-col>
                    </b-row>

                    <b-row >
                      <b-col sm="4"
                        ><p style="font-weight: 600">Daftar Pendiri Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in det.strukturOrganisasi" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.namaPengurus}};</span> 
                        </span> 
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4"
                        ><p style="font-weight: 600">NIK daftar Pendiri Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in det.strukturOrganisasi" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.identitas}};</span> 
                        </span> 
                      </b-col>
                    </b-row>

                    <b-row >
                      <b-col sm="4"
                        ><p style="font-weight: 600">Daftar Pembina Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in det.strukturOrganisasi" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.namaPengurus}};</span> 
                        </span> 
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4"
                        ><p style="font-weight: 600">NIK daftar Pembina Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in det.strukturOrganisasi" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.identitas}};</span> 
                        </span> 
                      </b-col>
                    </b-row>

                    <b-row >
                      <b-col sm="4"
                        ><p style="font-weight: 600">Daftar Penasehat Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in det.strukturOrganisasi" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.namaPengurus}};</span> 
                        </span> 
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4"
                        ><p style="font-weight: 600">NIK daftar Penasehat Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in det.strukturOrganisasi" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.identitas}};</span> 
                        </span> 
                      </b-col>
                    </b-row>

                    <b-row >
                      <b-col sm="4"
                        ><p style="font-weight: 600">Daftar Pelindung Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in det.strukturOrganisasi" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.namaPengurus}};</span> 
                        </span> 
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4"
                        ><p style="font-weight: 600">NIK daftar Pelindung Organisasi</p></b-col
                      >:<b-col sm="6">
                        <span v-for="(item,index) in det.strukturOrganisasi" :key="index">
                         <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.identitas}};</span> 
                        </span> 
                      </b-col>
                    </b-row>

                  </div>
                </div>
          </div>

          <b-row class="mb-3 mt-3" v-if="det.rekening && det.rekening.length > 0">
            <b-col>
              <b style="font-size:16px">
                Rekening
              </b>
            </b-col>
          </b-row>
          <div class="table-responsive" v-if="det.rekening && det.rekening.length > 0">
              <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                <thead>
                  <tr class="text-left">
                    <th style="width: 20px">No</th>
                    <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                    <th style="min-width: 50px" class="text-capitalize">Pemilik rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Jenis rekening</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, i) in det.rekening">
                    <tr v-bind:key="i">
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{ i + 1 }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="
                    text-dark-75
                    text-left
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{ item.noRekening}}</span
                        >
                      </td>
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    text-left
                    d-block
                    font-size-lg
                  "
                          >{{ item.namaBank}}</span
                        >
                      </td>
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    text-left
                    d-block
                    font-size-lg
                  "
                          >{{item.namaPemilikRekening}}</span
                        >
                      </td>
                      <td>
                        <span
                          class="
                    text-dark-75
                    text-left
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{item.masterJenisRekening.nama}}</span
                        >
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>

          <b-row class="mb-3 mt-8" v-if="det.cabangOrmas && det.cabangOrmas.length > 0">
            <b-col>
              <b style="font-size:16px">
                Cabang Ormas
              </b>
            </b-col>
          </b-row>
          <div class="table-responsive" v-if="det.cabangOrmas && det.cabangOrmas.length > 0">
              <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                <thead>
                  <tr class="text-left">
                    <th style="width: 20px">No</th>
                    <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                    <th style="min-width: 50px" class="text-capitalize">Alamat</th>
                    <th style="min-width: 50px" class="text-capitalize">Kabupaten / Kota</th>
                    <th style="min-width: 50px" class="text-capitalize">Propinsi</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, i) in det.cabangOrmas">
                    <tr v-bind:key="i">
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{ i + 1 }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="
                    text-dark-75
                    text-left
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{ item.masterLokasiSekretariat.nama_lokasi}}</span
                        >
                      </td>
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    text-left
                    d-block
                    font-size-lg
                  "
                          >{{ item.alamatSekretariat}}</span
                        >
                      </td>
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    text-left
                    d-block
                    font-size-lg
                  "
                          >{{ item.wilayahKabupatenKota.nama}}</span
                        >
                      </td>
                      <td>
                        <span
                          class="
                    text-dark-75
                    text-left
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{ item.wilayahProvinsi.nama}}</span
                        >
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </b-card>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>
<style>
#black-btn {
  background-color: black;
  color: white;
}

.tabs .card-header {
  background-color: #e5e5e5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}
.close{
  position: absolute;
  right: 30px;
}
.nav-item {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 10px;
  text-align: center;
  /* margin-right:3.35rem; */
}

.nav-item a {
  display: flex;
  justify-content: center;
}

.nav.nav-pills .nav-item {
  margin-right:10px !important;
  margin-left:10px !important;
}
.modal-header {
  display: flex;
  justify-content: center !important;
  border:none;
}
.modal-body {
  padding-top: 0px;
}
#modal-detail-permohonan___BV_modal_body_ {
  background-color:#FAFAFA !important;
}
</style>
<script>
import Swal from "sweetalert2";
export default {
  name: 'index-permohonan',
  data(){
    return {
      list: [],
      det: null,
      loading : false,
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL,
      ormasIdentify : null,
      pengurus: null,
      
    }
  },
  mounted(){
    this.getListPermohonan()
    this.getProfileInfo()
    this.getStructureOrganization()
  },
  methods:{
    replaceByDefault(e) {
      e.target.src = "/assets/images/default-thumbnail.jpg"
    },
    newTab(url){
      if(url.substring(0,4) == 'http') window.open(url);
      else window.open(this.fileURL + '/' + url);
    },
    getProfileInfo(){
      this.$store
        .dispatch("getProfileOrganization", null)
        .then((res) => {
          this.ormasIdentify = res.data
        })
    },
    getListPermohonan(){
      this.$store.dispatch('listPermohonanPerubahan', null).then((res)=>{
        this.list = res.data.data
      })
    },
    addSubmission(){
      for(var a = 0; a < this.list.length; a++){
        if(this.list[a].status == 0 || this.list[a].status == 4 ) {
          Swal.fire({
            title: "Tambah baru permohonan tidak dapat dilakukan!",
            text: "Permohonan anda sebelumnya belum selesai diproses",
            // icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#FF4433",
            confirmButtonText: "Baik",
          })
          return false
        }
      }
      this.$router.push('/permohonan/form')
    },
    deletePermohonan(/*id*/){
      // const context = this
      Swal.fire({
            title: "Anda yakin ingin menghapus permohonan?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Hapus",
          })
    },
    editPerubahan(item){
      var arr = []
      var frr = { cabang: false,
                  profile: false,
                  rekening: false,
                  structure: false
                }
      for(var i =0; i < item.mJenisPerubahanValues.length; i++){
        var a = item.mJenisPerubahanValues[i]
        if(a == 'Profil') {
          arr.push('profil')
          frr.profile = true
        }else if (a == 'Struktur Organisasi') {
          arr.push('struktur')
          frr.structure = true
        }else if (a == 'Rekening') {
          arr.push('rekening')
          frr.rekening = true
        }else if (a == 'Cabang Ormas') {
          arr.push('cabang')
          frr.cabang = true
        }
      }

      localStorage.setItem('form-permohonan', JSON.stringify(frr))
      localStorage.setItem('permohonan-arr', JSON.stringify(arr))
      this.$router.push('/permohonan/form/filling?id='+ item.id)
    },
    detailPerubahan(item){
      this.loading = true
      this.$store
      .dispatch("getDataPerubahan", item.id)
      .then((res) => {
        this.loading = false
        this.det = res.data.data
        this.$bvModal.show('modal-detail-permohonan')
      })
    },
    getStructureOrganization(){
      this.loading3 = true
      this.$store
        .dispatch("getStructureOrganization", localStorage.getItem('idOrmas'))
        // .dispatch("getStructureOrganization", 4)
        .then((res) => {
          this.pengurus = res.data.pengurusInti
          this.loading3 = false
        })
    },
  }
};
</script>

<style>
.table.table-head-bg thead tr,
.table.table-head-bg thead th {
  background-color: #333333;
}
.table.table-head-custom thead tr,
.table.table-head-custom thead th {
  color: #ffffff !important;
  text-align: center;
}
.table.table-head-custom tbody tr,
.table.table-head-custom tbody td {
  text-align: center;
}
</style>
